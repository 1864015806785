import axios, { AxiosInstance, AxiosError } from 'axios';

const baseURL = 'https://tribhus.shop:5000';

const axiosInstance: AxiosInstance & {
  isAxiosError: (payload: any) => payload is AxiosError;
} = axios.create({
  baseURL: baseURL
}) as any;

axiosInstance.isAxiosError = axios.isAxiosError;

type UpdateTokenFunction = (accessToken: string, refreshToken: string) => Promise<void>;

export function setupInterceptors(updateToken: UpdateTokenFunction) {
    axiosInstance.interceptors.request.use(
        async config => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );
    
    let isRefreshing = false;
    let failedQueue: { resolve: (token: string) => void; reject: (error: any) => void; }[] = [];

    const processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else if (token) {
                prom.resolve(token);
            } else {
                prom.reject('No token provided');
            }
        });
        failedQueue = [];
    };

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            // Não tenta refresh se o próprio endpoint de refresh falhou
            if (error.response?.status === 401 && 
                !originalRequest.url.includes('/users/refresh')) {
                
                if (!isRefreshing) {
                    isRefreshing = true;
                    console.log("Token expirado. Tentando refresh...");

                    try {
                        const refreshToken = localStorage.getItem('refreshToken');
                        const sessionId = localStorage.getItem('sessionId');
                        const platform = 'web';

                        const res = await axiosInstance.post('/users/refresh', {
                            refreshToken,
                            sessionId,
                            platform
                        });
                        console.log('Resposta do refresh no interceptor:', res.data);

                        if (res.status === 200) {
                            const newAccessToken = res.data.accessToken;
                            const newRefreshToken = res.data.refreshToken;

                            localStorage.setItem('accessToken', newAccessToken);
                            localStorage.setItem('refreshToken', newRefreshToken);
                            await updateToken(newAccessToken, newRefreshToken);

                            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);

                            console.log("Token atualizado com sucesso.");

                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axiosInstance(originalRequest);
                        }
                    } catch (refreshError) {
                        console.error("Erro ao atualizar o token:", refreshError);
                        processQueue(refreshError, null);

                        // Se o refresh falhou com 401, limpa tokens e força logout
                        if (refreshError.response?.status === 401) {
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('sessionId');
                            
                            window.location.href = '/PrimeiraTela';
                            return Promise.reject(refreshError);
                        }
                    } finally {
                        isRefreshing = false;
                    }
                }

                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                });
            }

            return Promise.reject(error);
        }
    );
}

export default axiosInstance;